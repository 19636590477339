import axios from './api.request'

/**
 * 上传图片
 */
export const uploadImage = (file, params) => {
  return axios.uploadFile('Index/upload', {name: 'file', file }, params)
}

/**
 * 获取Blob数据
 */
export const getBlob = (params, ignoreCodes = null) => {
  return axios.request({
    url: 'Index/getBlob',
    responseType: 'blob',
    params,
    method: 'get',
    ignoreCodes
  })
}
