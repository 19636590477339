<template>
  <div class="container">
    <div class="publicity-main" v-if="publicity != null">
      <!-- <div class="title">{{ publicity.title }}</div> -->
      <div class="title-box">
        <div class="top-title">{{ publicity.title }}</div>
      </div>
      <div class="title-box">
        <!-- <div class="top-title">描述</div> -->
        <div class="detail" v-html="detail"></div>
      </div>
      <div class="title-box">
        <!-- <div class="top-title">附件</div> -->
        <div class="upload-file__bd-box">
          <div class="upload-file__bd">
            <div v-for="(item, index) in publicity.attachments" :key="index" class="item"
              :style="index % 8 == 7 ? 'margin-right:0;' : ''">
              <!-- <viewer v-if="item.src !== null" :images="publicity.attachments.filter(p => p.src !== null).map(p => p.src)">
                <img :src="item.src">
              </viewer>
              <img v-else :src="nopic" @click="onViewFile(item)"> -->
              <!-- <div class="file-name">{{ item.name }}</div> -->
              <!-- <a class="file-name"
                :href="'https://view.officeapps.live.com/op/view.aspx?src='+(item.src?item.src:item.file_src)"
                target="_blank">{{index+1}}.{{item.file_name ? item.file_name : '查看文件'}}</a> -->
              <div class="file-name" @click="onDownloadImage(item.src ? item.src : item.file_src)">
                {{ index + 1 }}.{{ item.file_name ? item.file_name : '查看文件' }}
              </div>
            </div>
            <div v-for="(item, index) in publicity.user_attachments" :key="item.id" class="item"
              :style="index % 8 == 7 ? 'margin-right:0;' : ''">
              <viewer v-if="item.src !== null"
                :images="publicity.user_attachments.filter(p => p.src !== null).map(p => p.src)">
                <img :src="item.src">
              </viewer>
              <img v-else :src="nopic" @click="onViewFile(item)">
              <a class="file-name" :href="item.src || item.file_src" download="" target="_blank">{{ item.real_name }}</a>
            </div>
          </div>
        </div>
      </div>
      <Upload :show-upload-list="false" :before-upload="onUploadAttachment" action="#"
        accept="image/png, image/jpeg, image/jpg" style="display: inline-block;margin-top: 20px;">
        <!-- multiple -->
        <Button type="primary" icon="ios-cloud-upload-outline">上传盖章页</Button>
      </Upload>
      <div class="info">
        <!-- <div class="cover-box">
          <img class="cover" :src="publicity.image !== '' ? publicity.image : nopic" alt="">
        </div> -->
        <div class="info-r">
          <p class="line">
            <span class="label">发布人：</span>
            <span class="value">{{ publicity.real_name }}</span>
          </p>
          <p class="line" v-if="publicity.message">
            <span class="label">审核意见：</span>
            <span class="value">{{ publicity.message }}</span>
          </p>
          <!-- <p class="line">
            <span class="label">联系电话：</span>
            <span class="value">{{ publicity.mobile }}</span>
          </p> -->
          <p class="line">
            <span class="label">公示单位：</span>
            <span class="value">{{ publicity.company }}</span>
          </p>
          <p class="line">
            <span class="label">联系电话：</span>
            <span class="value">{{ publicity.company_phone }}</span>
          </p>
          <p class="line">
            <span class="label">详细地址：</span>
            <span class="value">{{ publicity.address }}</span>
          </p>
          <p class="line">
            <span class="label">发布时间：</span>
            <span class="value">{{ publicity.create_date }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="not-found" v-else>{{ loading ? '加载中...' : '未找到供需信息！' }}</div>
    <!-- <iframe id="preview_frame" class="detail-frame" runat="server" frameborder="no" border="0" marginwidth="0" marginheight="0" allowtransparency="yes"></iframe> -->
    <PreviewDocx v-model="docxInfo" />
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import {
  uploadAttachment,
  getPublicityDetail
} from '@/api/publicity.js'
import PreviewDocx from '@/components/PreviewDocx.vue'

export default {
  name: 'PublicityDetail',
  components: {
    PreviewDocx
  },
  data() {
    return {
      loading: true,
      nopic: require('@/assets/images/nopic.png'),

      id: null,
      publicity: null,
      detail: '',

      docxInfo: null
    }
  },
  computed: {
    // ...mapGetters(['hasLogin']),
  },
  mounted() {
    /* // 设置预览的 iframe
    const doc =
        document.getElementById('preview_frame').contentDocument ||
        document.frames.preview_frame.document

    // doc.body.style = 'margin:20px;'

    // 设置基地址
    const base = document.createElement('base')
    base.href = this.$config.baseUrl()
    doc.head.appendChild(base)

    this.htmlBase = base
    this.htmlDoc = doc
    this.htmlDoc.body.innerHTML = null */

    this.initData()
  },
  methods: {
    async initData() {
      this.id = this.$route.query.id
      console.log(this.$route)
      await this.getPublicityDetailData()
    },
    async getPublicityDetailData() {
      try {
        const params = {
          id: this.id
        }
        const res = await getPublicityDetail( /* params */ this.id)
        this.loading = false
        this.publicity = res.content. /* row */ content
        // this.detail = this.publicity.detail.replace(/src="/gi, 'src="' + this.$config.baseUrl() + '/')
        this.detail = this.publicity.detail
        // this.htmlDoc.body.innerHTML = this.publicity.detail
        console.log(this.publicity)
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    onViewFile(row) {
      const ext = row.save_path.substring(row.save_path.lastIndexOf('.') + 1)
      if (row.file_extension === 'doc' || row.file_extension === 'docx' ||
        row.file_extension === 'xls' || row.file_extension === 'xlsx' ||
        row.file_extension === 'ppt' || row.file_extension === 'pptx' ||
        row.file_extension === 'pdf') {
        // this.docxInfo = { detail: row }
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(row.file_src + "?t=" + Date.now()))
      }
    },
    async onDownloadImage(src) {
      var ext = src.substr(src.lastIndexOf(".") + 1);
      if (ext == 'docx') {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + src, "_blank")
      } else {
        var image = new Image()
        image.src = src
        image.onload = () => {
          image.style.margin = "0 auto"
          image.style.display = "block"
          var newWin = window.open("", "_blank")
          newWin.document.write(image.outerHTML)
        }
      }
      // const link = document.createElement('a') // 创建a标签
      // link.style.display = 'none' // 将a标签隐藏
      // link.href = src // 给a标签添加下载链接
      // link.target = '_blank'
      // link.setAttribute('download', /* '' */ this.publicity
      //   .title) // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
      // document.body.appendChild(link)
      // link.click() // 执行a标签
      // document.body.removeChild(link)
    },
    async onUploadAttachment(file) {
      console.log(file)
      await uploadAttachment({
        name: 'attachment',
        file
      }, {
        id: this.publicity.id
      }).then(async response => {
        await this.getPublicityDetailData()
      }).catch((err) => {
        this.$Message.error(err.message)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  min-height: calc(100vh - 64px);
  padding-bottom: 50px;
}

.publicity-main {

  .breadcrumb {
    padding-top: 14px;
    color: #888;
    font-size: 12px
  }

  .title {
    font-size: 20px;
    color: #333;
    padding-top: 16px;
  }

  .info {
    display: flex;
    padding-top: 20px;

    .cover-box {
      width: 500px;
      height: 375px;
      border: 1px solid #f0f0f0;

      .cover {
        width: 100%;
        height: 100%;
      }
    }

    .info-r {
      padding-left: 10px;

      .line {
        display: flex;
        padding: 4px 0;
        font-size: 16px;

        .label {
          width: 100px;
          text-align: right;
          color: #999;
        }

        .value {
          padding-left: 30px;
        }
      }
    }
  }

  .title-box {
    padding-top: 20px;

    .top-title {
      position: relative;
      padding-left: 10px;
      font-size: 20px;
      color: #333;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: #369;
      }
    }

    .detail {
      padding-top: 10px;

      font-size: 16px;
      line-height: 24px;
      word-break: break-all;
    }
  }
}

.not-found {
  font-size: 16px;
  color: #333;
  padding: 20px 0;
}

.detail-frame {
  width: 100%;
  min-height: 500px;
  padding-top: 10px;

  font-size: 16px;
  line-height: 24px;
  word-break: break-all;
}

.upload-file__bd-box {
  display: inline-block;
  min-width: 132px;
  width: 100%;
}

.upload-file__bd {
  position: relative;
  box-sizing: border-box;
  min-height: 160px;
  margin-top: 10px;
  padding: 10px 10px 0;
  border: solid 1px #dbdde1;
  display: flex;
  flex-direction: column;
  font-size: 18px;

  .item {
    position: relative;
    // width: 200px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      position: absolute;
      width: 100px;
      height: 100px;
      // width: 100%;
      // height: 100%;
      object-fit: cover;
    }

    .file-name {
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      text-align: left;

      overflow: hidden;
      word-break: break-all;
      /* break-all(允许在单词内换行。) */
      text-overflow: ellipsis;
      /* 超出部分省略号 */
      display: -webkit-box;
      /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical;
      /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 2;
      /** 显示的行数 **/
    }
  }

  .item:hover {
    .cover {
      display: block;
    }
  }
}
</style>
